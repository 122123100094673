import React, { Component }  from 'react';
import moment from 'moment';
import SchedulerComponent from '../FindCourtrooms/scheduler/scheduler';
import {SchedulerData, ViewTypes} from 'react-big-scheduler';
import ChangeBookingsGrid from '../Common/ChangeBookingsGrid';
import UserContext from '../../context/UserContext';
import {getDateLabel, globalSchedulerConfig} from '../../common/SchedulerConfig';
import { withAuth } from '@okta/okta-react';
import {fetchWithAuth} from '../../common/FetchUtil';

import '@progress/kendo-theme-default/dist/all.css';
import './Courtrooms.css';

const NO_RESULTS_FOUND = "No results found.";
const SEARCHING_FOR_COURTROOMS = "Searching for courtrooms....";
const DONE_SEARCHING_FOR_COURTROOMS = "Searching for courtrooms....done!";

class Courtrooms extends Component{
    constructor(props){
      super(props);

      this.child = React.createRef();

      let schedulerData = new SchedulerData(
            new Date(), 
            ViewTypes.Week, 
            false, 
            false, 
            globalSchedulerConfig({schedulerWidth: "70%", creatable: true, movable: true, checkConflict: true }),
            {
                getDateLabelFunc: getDateLabel,
            }
        );

        this.state = {
            viewModel: schedulerData,
            errorMessage: SEARCHING_FOR_COURTROOMS,
            bookingsInMyCourtrooms: [],
            skip: 0,
            take: 20,
        }
    }
    _change = (schedulerData, event) => {
        this.child.current.ref.current.change(schedulerData, event);
    }

    _fetchBookingsInMyCourtrooms = () => {
       
        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/owners/bookings?startDate="+moment(new Date()).format("YYYY-MM-DDT00:00:00"))
        .then(response => response.json())
        .then(json => {
            let bookings = [];
            json.forEach((booking) => {
                let jurisdiction = "Other";
                        if (booking.jurisdictionId)
                        jurisdiction = booking.jurisdiction.name;
                bookings.push({
                    id: booking.id,
                    date: moment(booking.startDate).format("DD/MM/YYYY"),
                    startTime: moment(booking.startDate).format("h:mm a"),
                    endTime: moment(booking.endDate).format("h:mm a"),
                    courthouse: booking.courthouse.name,
                    courtroom: booking.courtroom.name,
                    status: booking.bookingStatusDescription,
                    jurisdiction: jurisdiction,
                    booking: booking
                })
            });
            this.setState({bookingsInMyCourtrooms: bookings});
        });
    }

    _fetchMyCourtrooms = () => {
        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/owners/courtrooms?startDate="+moment(this.state.viewModel.startDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")+"&endDate="+moment(this.state.viewModel.endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"))
        .then(response => response.json())
        .then(json => {
            this.setState({ errorMessage: DONE_SEARCHING_FOR_COURTROOMS });
            var viewModel = this.state.viewModel;
                viewModel.resources = [];
                var retrievedResources = [];
                var bookings = [];
                json.forEach((resource) => {
                    retrievedResources.push({id: resource.id, name: resource.name});
                    resource.bookings.forEach((booking )=> {
                        let bookingTitle = "[Other] " + booking.bookingStatusDescription;
                        if (booking.jurisdictionId)
                            bookingTitle = "["+booking.jurisdiction.name+"] " + booking.bookingStatusDescription;
                        bookings.push({id: booking.id, 
                            start: booking.startDate, 
                            end: booking.endDate, 
                            resourceId: resource.id, 
                            title: bookingTitle,
                            movable: false,
                            resizable: false,
                            other: {
                                editableEvent: true,
                                change: this._change,
                                booking: booking,
                            }});
                    });
                    
                });
                viewModel.setResources(retrievedResources);
                viewModel.setEvents(bookings);
                this.setState({
                    viewModel: viewModel,
                    errorMessage: viewModel.resources.length === 0 ? NO_RESULTS_FOUND : null,
                });
        });
    }

    _onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        this._fetchMyCourtrooms();
        this.setState({
            viewModel: schedulerData
        })
    }

    _prevClick = (schedulerData)=> {
        schedulerData.prev();
        this._fetchMyCourtrooms();
        this.setState({
            viewModel: schedulerData
        })
    }

    _nextClick = (schedulerData)=> {
        schedulerData.next();
        this._fetchMyCourtrooms();
        this.setState({
            viewModel: schedulerData
        })
    }

    componentDidMount(){
        this._fetchMyCourtrooms();
        this._fetchBookingsInMyCourtrooms();
    }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    }

    render(){
        let element;
        if (this.state.errorMessage){
            element = (
                <div className="au-body">
                    <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            {this.state.errorMessage}
                        </div>
                      </div></div>
                </div>);
        }
        else{
            element = (<div className="au-body">
                <SchedulerComponent 
                ref={this.child} 
                viewModel={this.state.viewModel} 
                prevClick={this._prevClick} 
                nextClick={this._nextClick}
                onViewChange={this._onViewChange}/>
            </div>);
        }

        return (
            <div className="au-body">
                <div className="container">
                <div className="row">
                        <div className="col-xs-12">
                            <h2 className="au-sidenav__title">My courtrooms</h2>
                            {element}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <ChangeBookingsGrid title={"Bookings in my courtrooms"} 
                            pageSize={20} 
                            pageChange={this.pageChange}
                            totalItems={this.state.bookingsInMyCourtrooms.length}
                            skip={this.state.skip}
                            take={this.state.take}
                            data={this.state.bookingsInMyCourtrooms.slice(this.state.skip, this.state.take + this.state.skip)}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Courtrooms.contextType = UserContext;

export default withAuth(Courtrooms);