import React, {Component} from 'react';
import Scheduler, {SchedulerData, ViewTypes} from 'react-big-scheduler';
import withDragDropContext from '../FindCourtrooms/scheduler/withDnDContext';
import moment from 'moment';
import ReactModal from 'react-modal';
import UserContext from '../../context/UserContext';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import InfoButton from '../Common/InfoButton';
import {getDateLabel, globalSchedulerConfig, eventItemTemplateResolver, eventItemPopoverTemplateResolver} from '../../common/SchedulerConfig';
import CancelBooking from './CancelBooking';
import AmendBooking from './AmendBooking';
import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from '../../common/FetchUtil';

import 'react-big-scheduler/lib/css/style.css';
import './BookCourtroom.css';

const payload = {viewStartDate: null, viewEndDate: null, jurisdictionId: null, notes2: "", startDate: null, endDate: null};

const SELECT_VALID_JURISDICTION_OR_OTHER = "Please select Jurisdiction from drop down or Tick 'Other'";
const SELECTED_JURISDICTION_AND_OTHER = "You have picked a Jurisdiction and selected other. Please choose only one.";
const NOTES_EXCEEDING_LIMIT = "Note(s) section exceeds 200 characters. Please shorten the text. ";
const NOTES_CANNOT_BE_BLANK = "Note(s) section cannot be blank when 'Other' is selected. Please provide details of Court room booking. ";
const BOOKING_REQUEDT_LEADS_TO_CONFLICT = "Requested booking leads to booking conflict. Please tick 'Ignore conflict' and request the booking again.";
const NOTES_INFO_MESSAGE = "Note – Requested selection will create recurring booking for all 5 days. Please select the Day(s) you want the booking to recur.";
const DATE_RANGE_INFO_MESSAGE = "Please enter a date range within 2 years from end date of the booking.";

class BookCourtroom extends Component{
    constructor(props){
        super(props);

        let schedulerData = new SchedulerData(
            this.props.startDate, 
            this.props.viewType, 
            false, 
            false, 
            globalSchedulerConfig({schedulerWidth:"70%", checkConflict: false, movable: true}),
            {
                getDateLabelFunc: getDateLabel,
            }
        );

        schedulerData.addResource(this.props.resource);

        if (this.props.editMode === false){
            schedulerData.addEvent(this.props.event);
        }

        this.getWeekInfo(this.props.event.start);

        payload.viewStartDate = this.props.startDate;
        payload.viewEndDate = this.props.endDate;

        payload.startDate = this.props.event.start;
        payload.endDate = this.props.event.end;
        let endAfterTwoYears = moment(this.props.endDate).add(2, "years").format("MM/DD/YYYY");
        
        this.state = {
          viewModel: schedulerData,
          selectedJurisdiction: 0,
          notes: undefined,
          jurisdictions: [],
          startDate: new Date(this.props.startDate), 
          endDate: new Date(this.props.endDate),
          otherChecked: false,
          ignoreChecked: false,
          justiceWeekChecked: false,
          monthOptionSelected: 2,
          weekOptionSelected: 0,
          weekInfo: {},
          weekNo: 0,
          showDaySelectorRow: true,
          mondaySelected: false,
          tuesdaySelected: false,
          wednesdaySelected: false,
          thursdaySelected: false,
          fridaySelected: false,
          endAfterDate: new Date(endAfterTwoYears),
          endAfterTwoYears: new Date(endAfterTwoYears),
          recurringOptionsEnabled: false,
          mondayDate: new Date(),
          fridayDate: new Date(),
          mondayDisabled: true,
          tuesdayDisabled: false,
          wednesdayDisabled: false,
          thursdayDisabled: false,
          fridayDisabled: false,
          requestButtonEnabled: true,
          successMessage: undefined
      }
    }

    onMonthOptionChanged = (option) => {
        this.setState({monthOptionSelected: option});
    }

    onWeekOptionChanged = (option) => {
        this.setState({weekOptionSelected: option});
    }

    onJusticeWeekChanged= (event) => {
        var justiceWeekChecked = !this.state.justiceWeekChecked;
        this.setState({justiceWeekChecked: justiceWeekChecked });
        this.getWeekNo(this.state.weekInfo, justiceWeekChecked);
    }

    onIgnoreChange = (event) => {
        this.setState({ignoreChecked: !this.state.ignoreChecked});
    }

    onRecurringOptionsChange = (event) => {
        this.setState({recurringOptionsEnabled: !this.state.recurringOptionsEnabled});
    }

    onMondayChange = (event) => {
        this.setState({mondaySelected: !this.state.mondaySelected});
    }

    onTuesdayChange = (event) => {
        this.setState({tuesdaySelected: !this.state.tuesdaySelected});
    }

    onWednesdayChange = (event) => {
        this.setState({wednesdaySelected: !this.state.wednesdaySelected});
    }

    onThursdayChange = (event) => {
        this.setState({thursdaySelected: !this.state.thursdaySelected});
    }

    onFridayChange = (event) => {
        this.setState({fridaySelected: !this.state.fridaySelected});
    }

    onOtherChange = (event) => {
        this.setState({otherChecked: !this.state.otherChecked});
    }
    
    onJurisdictionsChange = (event) => {
        payload.jurisdictionId = event.target.value;
        this.setState({selectedJurisdiction: payload.jurisdictionId});
    }

    onNotesChange = (event) => {
        payload.notes = event.target.value;
        this.setState({notes: payload.notes});
    }

    validateJurisdictionSelection = () => {
        if (this.state.selectedJurisdiction === 0 && this.state.otherChecked === false)
        {
            this.setState({ignoreChecked: false, showValidationMessage: true, validationMessage: SELECT_VALID_JURISDICTION_OR_OTHER});
            return false;
        }
        if (this.state.selectedJurisdiction > 0 && this.state.otherChecked === true)
        {
            this.setState({ignoreChecked: false, showValidationMessage: true, validationMessage: SELECTED_JURISDICTION_AND_OTHER});
            return false;
        }

        return true;
    }

    validateNotes = () => {
        if (this.state.notes && this.state.notes.length > 200)
        {
            this.setState({ignoreChecked: false, showValidationMessage: true, validationMessage: NOTES_EXCEEDING_LIMIT});
            return false;
        }
        return true;
    }

    validateOtherSelection = () => {
        if (this.state.otherChecked === true && 
            (!!!this.state.notes || (this.state.notes.length === 0)))
        {
            this.setState({ignoreChecked: false, showValidationMessage: true, validationMessage: NOTES_CANNOT_BE_BLANK});
            return false;
        }
        return true;
    }

    _hideMessages = () => {
        this.setState({
            showConflictMessage: false, 
            showOtherMessage: false, 
            showSuccessMessage: false, 
            showValidationMessage: false,
            requestButtonEnabled: false});
    }

    _doesValidationPass = () => {
        return this.validateJurisdictionSelection() && this.validateOtherSelection() && this.validateNotes()
    }

    _getSelectedDays = () => {
        let selectedDays = [];

        if (this.state.mondaySelected) selectedDays.push(1);
        if (this.state.tuesdaySelected) selectedDays.push(2);
        if (this.state.wednesdaySelected) selectedDays.push(3);
        if (this.state.thursdaySelected) selectedDays.push(4);
        if (this.state.fridaySelected) selectedDays.push(5);
            
        return selectedDays;
    }

    requestBooking = () => {
        this._hideMessages();

        if (this._doesValidationPass()){
            let selectedDays = this._getSelectedDays();

            let bookingTypeId = 1;
            let recurringOptions = null;
            if (this.state.recurringOptionsEnabled === true){
                bookingTypeId = 2;

                recurringOptions = {
                    endAfter: moment(this.state.endAfterDate).format("YYYY-MM-DD HH:mm:ss"),
                    isJusticeAllocatedWeek: this.state.justiceWeekChecked,
                    monthOptions: this.state.monthOptionSelected,
                    weekOptions: this.state.weekOptionSelected,
                    selectedDays: selectedDays
                }
            }

            fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/courtrooms/" + this.props.resource.id + "/bookings", {
                method: 'POST',
                body: JSON.stringify({
                        startDate: payload.startDate,
                        endDate: payload.endDate,
                        notes: this.state.notes,
                        jurisdictionId: payload.jurisdictionId !== null || payload.jurisdictionId > 0 ?  payload.jurisdictionId  : null,
                        bookingTypeId: bookingTypeId,
                        ignoreConflict: this.state.ignoreChecked,
                        selectedDays: selectedDays,
                        recurringOptions: recurringOptions
                    })
                })                
                .then(response => response.json())
                .then(json => {
                    if (json.errorCode === 4)
                    {
                        this._showConflictMessage();
                    }
                    else if (json.errorCode === -1)
                    {
                        this._showSuccessMessage(json.message);
                    }
                    else{
                        this._showOtherMessage(json.message);
                    }
                })
                .catch((error) => {
                    this._showOtherMessage("There was an error requesting the booking.");
                });
        }
        else 
        {
            this.setState({ requestButtonEnabled: true });
        }
    }

    _showConflictMessage = () => {
        this.setState({showConflictMessage: true, 
            showOtherMessage: false, 
            showSuccessMessage: false, 
            showValidationMessage: false, 
            requestButtonEnabled: true});
    }

    _showSuccessMessage = (message) => {
        this.setState({showConflictMessage: false, 
            showOtherMessage: false, 
            showSuccessMessage: true, 
            showValidationMessage: false, 
            requestButtonEnabled: true,
            successMessage: message});
        setTimeout(() => {window.location.href = "/"}, 5000);
    }

    _showOtherMessage = (message) => {
        this.setState({showConflictMessage: false, showOtherMessage: true, showSuccessMessage: false, showValidationMessage: false, requestButtonEnabled: true,
        otherMessage: message});
    }

    getBookings = () => {
        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/courtrooms/" + this.props.resource.id + "/bookings?startDate="+moment(payload.viewStartDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")+"&endDate="+moment(payload.viewEndDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"))
        .then(response => response.json())
        .then(json => {
            let viewModel = this.state.viewModel;
            var bookings = [];
            let jurisdictionId = 0;
            let otherChecked = false;
            let notes = undefined;
            let recurringOptionsEnabled = false;
            let justiceWeekChecked = false;
            let monthOptionSelected = 2;
            let weekOptionSelected = 0;
            let endAfterDate = new Date(moment(this.props.endDate).add(2, "years").format("MM/DD/YYYY"));

            json.forEach((booking) => {
                let bookingTitle = "[Other] Booking";
                if (booking.jurisdictionId)
                    bookingTitle = "["+booking.jurisdiction.name+"] Booking";

                let movable = false;
                let resizable = false;
                let editable = false;
                
                if (this.props.event.id === booking.id)
                {
                    movable = true;
                    resizable = true;
                    editable = false;
                    bookingTitle = "Change Booking";
                    jurisdictionId = booking.jurisdictionId == null || booking.jurisdictionId === 0 ? undefined : booking.jurisdictionId ;
                    otherChecked = booking.jurisdictionId == null || booking.jurisdictionId === 0 ? true : false;
                    notes = booking.notes == null ? undefined : booking.notes;
                    recurringOptionsEnabled = booking.bookingTypeId === 2;
                    
                    if (recurringOptionsEnabled === true){
                        justiceWeekChecked = booking.recurringOptions.isJusticeAllocatedWeek;
                        monthOptionSelected = booking.recurringOptions.monthOptions;
                        weekOptionSelected = booking.recurringOptions.weekOptions;
                        endAfterDate = new Date(booking.recurringOptions.endAfter);
                    }
                }

                bookings.push({id: booking.id, 
                    start: booking.startDate, 
                    end: booking.endDate, 
                    resourceId: booking.courtroomId, 
                    title: bookingTitle, 
                    movable: movable,
                    resizable: resizable,
                    other: {
                        editableEvent: editable,
                        booking: booking
                    }
                });
            });
            
            viewModel.setEvents(bookings);

            if (this.props.editMode === false){
                viewModel.addEvent(this.props.event);
            }
            this.setState({ 
                viewModel: viewModel,
                selectedJurisdiction: jurisdictionId,
                otherChecked: otherChecked,
                notes: notes,
                recurringOptionsEnabled: recurringOptionsEnabled,
                justiceWeekChecked: justiceWeekChecked,
                monthOptionSelected: monthOptionSelected,
                weekOptionSelected: weekOptionSelected,
                endAfterDate: endAfterDate,
            });

            if (this.state.weekInfo){
                this.getWeekNo(this.state.weekInfo, justiceWeekChecked);
            }
        });
    }
    
    getWeekNo = (weekInfo, justiceWeekChecked) => {
        var week = _.filter(weekInfo, (week) => { return week.isJusticeAllocatedWeekNo === justiceWeekChecked });
        if (week[0]){
            this.setState({weekNo: week[0].weekNo});
        }
    }

    getWeekInfo(date){
        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/calendars/weeks?date="+moment(date).format("YYYY-MM-DD"))
        .then(response => response.json())
        .then(json => {
            this.setState({ weekInfo:  json});
            this.getWeekNo(json, this.state.justiceWeekChecked);
            });
    }

    getDaysInView = (start, end) => {
        var days = moment(end).diff(start, "days");
        
        return days;
    }

    showDaySelectorRow = (startDate, endDate) => {
        var days = this.getDaysInView(startDate, endDate);

        var selectedDays = [];

        for(var day = 0; day <= days; day++){
            var momentDay = moment(startDate).add(day, "days");
            var selectedDay = momentDay.day();
            selectedDays.push(selectedDay);
        }

        var mondaySelected, tuesdaySelected, wednesdaySelected, thursdaySelected, fridaySelected = false;

        mondaySelected = _.filter(selectedDays, function(item){ return item === 1}).length === 1;
        tuesdaySelected =  _.filter(selectedDays, function(item){ return item === 2}).length === 1;
        wednesdaySelected =  _.filter(selectedDays, function(item){ return item === 3}).length === 1;
        thursdaySelected =  _.filter(selectedDays, function(item){ return item === 4}).length === 1;
        fridaySelected =  _.filter(selectedDays, function(item){ return item === 5}).length === 1;
        
        this.setState({showDaySelectorRow: days > 0 && this.state.viewModel.viewType !== 0, 
            mondaySelected: mondaySelected,
            tuesdaySelected: tuesdaySelected,
            wednesdaySelected: wednesdaySelected,
            thursdaySelected: thursdaySelected,
            fridaySelected: fridaySelected,
            mondayDisabled: !mondaySelected,
            tuesdayDisabled: !tuesdaySelected,
            wednesdayDisabled: !wednesdaySelected,
            thursdayDisabled: !thursdaySelected,
            fridayDisabled: !fridaySelected
        });
    }

    isRecurringOptionsDisabled(){
        return !this.state.recurringOptionsEnabled || this.props.editMode;
    }

    isEvenMonthEnabled = () => {
        var isEvenMonth = moment(payload.startDate).month() % 2 !== 0 || moment(payload.endDate).month() % 2 !== 0;
        return this.isRecurringOptionsDisabled() || !isEvenMonth;
    }

    isOddMonthEnabled = () => {
        var isOddMonth = moment(payload.startDate).month() % 2 === 0 || moment(payload.endDate).month() % 2 === 0;
        return this.isRecurringOptionsDisabled() || !isOddMonth ;
    }

    endAfterDateChanged = newDate => {
        this.setState({ endAfterDate: newDate});
      };

    initialise = () => {
        this.getBookings();
        
        this.showDaySelectorRow(this.props.event.start, this.props.event.end);

        var mondayDate, fridayDate = new Date();

        mondayDate = new Date(moment(payload.viewStartDate).add(1, "days").format("YYYY-MM-DD 09:00:00"));
        fridayDate = new Date(moment(payload.viewEndDate).add(-1, "days").format("YYYY-MM-DD 17:00:00"));

        if (this.props.editMode === false)
        {
            this.setState({
                notes: undefined,
                otherChecked: false,
                selectedJurisdiction: 0
            });
        }

        this.setState({
            mondayDate: mondayDate,
            fridayDate: fridayDate});
    }

    componentDidMount = () => {
        
        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/jurisdictions")
        .then(response => response.json())
        .then(json => this.setState({ jurisdictions:  json}));

        this.initialise();
    }

    _isMonthOptionSelected(month){
        var selected = this.state.monthOptionSelected === month;

        if (month === 0 && this.isOddMonthEnabled() === true)
            selected = false;
        if (month === 1 && this.isEvenMonthEnabled() === true)
            selected = false;

        if (month === 2 && this._isMonthOptionSelected(0) === false && this._isMonthOptionSelected(1) === false )
        {
            selected = true;
        }

        return selected;
    }

    render(){
        let modal = "";
        let conflictMessage = "";
        let successMessage = "";
        let otherMessage = "";
        let validationMessage = "";
        let daySelectorRow = "";

        if (this.state.showValidationMessage)
        {
            validationMessage = (<div>
                                    <label style={{color: "#B81237"}}>{this.state.validationMessage}</label>
                                </div>
                                );
        }

        if (this.state.showSuccessMessage)
        {
            successMessage = (<div>
                                <div className="form-item">
                                    <label style={{color: "#00A908"}}>{this.state.successMessage} Click <a href="/">here</a>  to navigate to your "My Bookings" Page.</label>
                                </div>
                            </div>);
        }
        if (this.state.showOtherMessage)
        {
            otherMessage = (<div>
                                <div className="form-item">
                                    <label style={{color: "#B81237"}}>{this.state.otherMessage}</label>
                                </div>
                            </div>);
        }
        if (this.state.showConflictMessage)
        {
            conflictMessage = (
                <div>
                    <label style={{color: "#B81237"}}>{BOOKING_REQUEDT_LEADS_TO_CONFLICT}</label>
                        <label className="au-control-input au-control-input--block">
                        <input className="au-control-input__input" type="checkbox" defaultChecked={this.state.ignoreChecked} onChange={this.onIgnoreChange}></input>
                        <span className="au-control-input__text">Ignore conflict</span>
                    </label>
                </div>);
        }

        if (this.state.showDaySelectorRow)
        {
            daySelectorRow = (<div className="row">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-2">
                                        <label className="au-control-input au-control-padding-small">
                                        <input className="au-control-input__input" type="checkbox" checked={this.state.mondaySelected} onChange={this.onMondayChange} disabled={this.state.mondayDisabled}></input>
                                            <span className="au-control-input__text">Monday</span>
                                        </label>
                                    </div>
                                    <div className="col-lg-2">
                                        <label className="au-control-input au-control-padding-small">
                                            <input className="au-control-input__input" type="checkbox" checked={this.state.tuesdaySelected} onChange={this.onTuesdayChange} disabled={this.state.tuesdayDisabled}></input>
                                            <span className="au-control-input__text">Tuesday</span>
                                        </label>
                                    </div>
                                    <div className="col-lg-2">
                                        <label className="au-control-input au-control-padding-small">
                                            <input className="au-control-input__input" type="checkbox" checked={this.state.wednesdaySelected} onChange={this.onWednesdayChange} disabled={this.state.wednesdayDisabled}></input>
                                            <span className="au-control-input__text">Wednesday</span>
                                        </label>
                                    </div>
                                    <div className="col-lg-2">
                                        <label className="au-control-input au-control-padding-small">
                                            <input className="au-control-input__input" type="checkbox" checked={this.state.thursdaySelected} onChange={this.onThursdayChange} disabled={this.state.thursdayDisabled}></input>
                                            <span className="au-control-input__text">Thursday</span>
                                        </label>
                                    </div>
                                    <div className="col-lg-2">
                                        <label className="au-control-input au-control-padding-small">
                                            <input className="au-control-input__input" type="checkbox" checked={this.state.fridaySelected} onChange={this.onFridayChange} disabled={this.state.fridayDisabled}></input>
                                            <span className="au-control-input__text">Friday</span>
                                        </label>
                                    </div>
                                    <div className="col-lg-1">
                                        <InfoButton infoMessag={NOTES_INFO_MESSAGE}/>
                                    </div>
                                </div>);
        }


        if (this.props.showModal)
        {
            modal = (<ReactModal isOpen={true}
                className="ReactModal_DOJ"
                ariaHideApp={false}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                    position: 'absolute',
                    top: '40px',
                    left: '80px',
                    right: '80px',
                    bottom: '40px',
                    border: '1px solid #ccc',
                    background: '#fff',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '20px'
                    }
                }}>
                <span> <Scheduler schedulerData={this.state.viewModel}
                                prevClick={this.prevClick}
                                nextClick={this.nextClick}
                                onSelectDate={this.onSelectDate}
                                onViewChange={this.onViewChange}
                                eventItemClick={this.eventClicked}
                                viewEventClick={this.book}
                                viewEvent2Click={this.cancelNewBooking}
                                updateEventStart={this.updateEventStart}
                                updateEventEnd={this.updateEventEnd}
                                moveEvent={this.moveEvent}
                                newEvent={this.newEvent}
                                eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
                                eventItemTemplateResolver={eventItemTemplateResolver}/> </span>
                <div className="au-body au-grid container-fluid">
                    {daySelectorRow}
                    <div className="row">
                        <div className="col-lg-4">
                            <label className="label--block" htmlFor="select1">Jurisdiction</label>
                            <select id="select1" className="au-select" onChange={this.onJurisdictionsChange} value={this.state.selectedJurisdiction}>
                            
                            <option value="0">Please select</option>
                            {this.state.jurisdictions.map((jurisdiction) => { 
                                return (<option key={jurisdiction.id} value={jurisdiction.id}>{jurisdiction.name}</option>);
                            })}
                            </select>
                            
                            <label className="au-control-input au-control-padding-small">
                                <input className="au-control-input__input" type="checkbox" checked={this.state.otherChecked} onChange={this.onOtherChange}></input>
                                <span className="au-control-input__text">Other</span>
                            </label>
                            {validationMessage}
                        </div>
                    
                        <div className="col-lg-8">
                            <label htmlFor="help-comment"> Notes </label>
                            <textarea className="au-text-input au-text-input--block" name="help-comment" id="help-comment" required="" onChange={this.onNotesChange} value={this.state.notes}></textarea>
                        </div>
                    </div>
                <div className="row">
                    <div className="col-lg-4">      
                        <label className="au-control-input">
                            <input className="au-control-input__input" type="checkbox" checked={this.state.recurringOptionsEnabled} onChange={this.onRecurringOptionsChange} disabled={this.props.editMode}></input>
                            <span className="au-control-input__text">Recurring</span>
                        </label>
                    </div>
                    <div className="col-lg-4">
                        <label className="au-control-input">
                            <input className="au-control-input__input" type="checkbox" checked={this.state.justiceWeekChecked} onChange={this.onJusticeWeekChanged} disabled={this.isRecurringOptionsDisabled()} ></input>
                            <span className="au-control-input__text">Begin 1st Monday of the Month.</span>
                        </label>
                    </div>
                    <div className="col-lg-4">
                        <label className="au-control-input">
                            <input className="au-control-input__input" type="radio" name="radio-week" checked={this.state.weekOptionSelected === 0} disabled={this.isRecurringOptionsDisabled()} onChange={this.onWeekOptionChanged.bind(this, 0)}></input>
                            <span className="au-control-input__text">Every Week</span>
                        </label>
                        <label className="au-control-input">
                            <input className="au-control-input__input" type="radio" name="radio-week" checked={this.state.weekOptionSelected === 1} disabled={this.isRecurringOptionsDisabled()} onChange={this.onWeekOptionChanged.bind(this, 1)}></input>
                            <span className="au-control-input__text">Week ({this.state.weekNo})</span>
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">      
                        
                    </div>
                    <div className="col-lg-4">
                        <label className="au-control-input" >
                            <input className="au-control-input__input" type="radio" name="radio-month" checked={this._isMonthOptionSelected(0)} disabled={this.isOddMonthEnabled()} onChange={this.onMonthOptionChanged.bind(this, 0)}></input>
                            <span className="au-control-input__text">Odd Months</span>
                        </label>
                        <label className="au-control-input">
                            <input className="au-control-input__input" type="radio" name="radio-month" checked={this._isMonthOptionSelected(1)} disabled={this.isEvenMonthEnabled()} onChange={this.onMonthOptionChanged.bind(this, 1)}></input>
                            <span className="au-control-input__text">Even Months</span>
                        </label>
                        <label className="au-control-input">
                            <input className="au-control-input__input" type="radio" name="radio-month" checked={this._isMonthOptionSelected(2)} disabled={this.isRecurringOptionsDisabled()} onChange={this.onMonthOptionChanged.bind(this, 2)}></input>
                            <span className="au-control-input__text">All Months</span>
                        </label>
                    </div>
                    <div className="col-lg-4">      
                        <label className="label--block" disabled={this.isRecurringOptionsDisabled()}>End week prior to:</label>
                        <DatePicker
                            selected={this.state.endAfterDate}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endAfterDate}
                            minDate={this.state.startDate}
                            maxDate={this.state.endAfterTwoYears}
                            onChange={this.endAfterDateChanged}
                            dateFormat="dd/MM/yyyy"
                            className="au-text-input"
                            disabled={this.isRecurringOptionsDisabled()}/>
                            <InfoButton infoMessage={DATE_RANGE_INFO_MESSAGE}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">      
                    {this.props.editMode && <AmendBooking 
                                                bookingId={this.props.event.id} 
                                                startDate={payload.startDate}
                                                endDate={payload.endDate}
                                                notes={this.state.notes}
                                                jurisdictionId={this.state.selectedJurisdiction}
                                                ignoreConflict={this.state.ignoreChecked}
                                                showConflictMessage={this._showConflictMessage}
                                                showSuccessMessage={this._showSuccessMessage}
                                                showOtherMessage={this._showOtherMessage}
                                                isRecurringBooking={this.state.recurringOptionsEnabled}
                                                doesValidationPass={this._doesValidationPass}
                                                hideMessages={this._hideMessages}
                                                selectedDays={this._getSelectedDays}
                                                hideAllOccurances={this.state.showDaySelectorRow}
                                                />}
                                                
                    {this.props.editMode === false && <button type="button" className="au-btn" disabled={this.state.requestButtonEnabled === false} onClick={this.requestBooking}>Request Booking</button>}
                    </div>
                    {this.props.editMode && <div className="col-lg-3">
                        <CancelBooking bookingId={this.props.event.id} isRecurringBooking={this.state.recurringOptionsEnabled}/>
                    </div>}
                    <div className="col-lg-2">
                        <button type="button" className="au-btn au-btn--secondary" onClick={this.props.closeModal.bind(this, this.props.editMode)} >Close</button>
                    </div>
                    <div className="col-lg-4">
                    {conflictMessage}
                    {successMessage}
                    {otherMessage}
                    
                    </div>
                </div>

                
            </div>
            </ReactModal>)
        }

        return (<div>{modal}</div>)
    }
    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.addEvent(this.props.event);
        this.setState({
            viewModel: schedulerData,
        });
        payload.viewStartDate = schedulerData.startDate;
        payload.viewEndDate = schedulerData.endDate;
        
        var days = this.getDaysInView(schedulerData.startDate, schedulerData.endDate);
        
        this.setState({showDaySelectorRow: days > 0 && view.viewType !== ViewTypes.Day});

        this.getBookings();
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        this.setState({
            viewModel: schedulerData
        })
    }

    updateEventStart = (schedulerData, event, newStart) => {
        var dayOfWeekStart = moment(newStart).weekday();
        var dayOfWeekEnd = moment(event.end).weekday();

        if ((dayOfWeekStart >= 1 && dayOfWeekStart <= 5) && (dayOfWeekEnd >= 1 && dayOfWeekEnd <= 5))
        {   //its a week selection, its valid.
            // eslint-disable-next-line
            newStart = newStart;
        }
        else if ((dayOfWeekStart === 0 && dayOfWeekEnd === 0) || (dayOfWeekStart === 6 && dayOfWeekEnd === 6))
        { //its a weekend only
            // eslint-disable-next-line
            newStart = newStart;
        }
        else
        {
            newStart = event.start;
        }
        
        schedulerData.updateEventStart(event, newStart);
        payload.startDate = newStart;
        payload.endDate = event.end;
        this.setState({
            viewModel: schedulerData
        })
        this.showDaySelectorRow(newStart, event.end);
        this.getWeekInfo(event.start);
    }

    updateEventEnd = (schedulerData, event, newEnd) => {
        var dayOfWeekStart = moment(event.start).weekday();
        var dayOfWeekEnd = moment(newEnd).weekday();

        if ((dayOfWeekStart >= 1 && dayOfWeekStart <= 5) && (dayOfWeekEnd >= 1 && dayOfWeekEnd <= 5))
        {   //its a week selection, its valid.
            // eslint-disable-next-line
            newEnd = newEnd;
        }
        else if ((dayOfWeekStart === 0 && dayOfWeekEnd === 0) || (dayOfWeekStart === 6 && dayOfWeekEnd === 6))
        { //its a weekend only
            // eslint-disable-next-line
            newEnd = newEnd;
        }
        else
        {
            newEnd = event.end;
        }

        schedulerData.updateEventEnd(event, newEnd);
            payload.startDate = event.start;
            payload.endDate = newEnd;
        this.setState({
            viewModel: schedulerData
        })
        this.showDaySelectorRow(event.start, newEnd);
        this.getWeekInfo(event.start);
    }

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        var days = this.getDaysInView(event.start, event.end);

        if (days > 0)
        {
            var dayOfWeekStart = moment(start).weekday();
            var dayOfWeekEnd = moment(end).weekday();

            if ((dayOfWeekStart >= 1 && dayOfWeekStart <= 5) && (dayOfWeekEnd >= 1 && dayOfWeekEnd <= 5))
            {   //its a week selection, its valid.
                // eslint-disable-next-line
                start = start;
                // eslint-disable-next-line
                end = end;
            }
            else
            {
                start = event.start;
                end = event.end;
            }
        }

        schedulerData.moveEvent(event, slotId, slotName, start, end);
        payload.startDate = start;
        payload.endDate = end;
        this.setState({
            viewModel: schedulerData
        })
        this.showDaySelectorRow(start, end);
        this.getWeekInfo(event.start);
    }

    prevClick = (schedulerData)=> {
        
        schedulerData.prev();
        schedulerData.addEvent(this.props.event);
        this.setState({
            viewModel: schedulerData, 
        })

        payload.viewStartDate = schedulerData.startDate;
        payload.viewEndDate = schedulerData.endDate;

        this.initialise();
    }

    nextClick = (schedulerData)=> {
        schedulerData.next();
        schedulerData.addEvent(this.props.event);
        this.setState({
            viewModel: schedulerData,
        })
        payload.viewStartDate = schedulerData.startDate;
        payload.viewEndDate = schedulerData.endDate;
        
        this.initialise();
    }
}

BookCourtroom.contextType = UserContext;

export default withAuth(withDragDropContext(BookCourtroom))
