import React, {Component} from 'react'
import moment from 'moment'
import Scheduler, {ViewTypes} from 'react-big-scheduler'
import withDragDropContext from './withDnDContext'
import 'react-big-scheduler/lib/css/style.css'
import Bookcourtroom from "../../Booking/Bookcourtroom";
import './scheduler.css'
import {eventItemTemplateResolver, eventItemPopoverTemplateResolver} from '../../../common/SchedulerConfig';

class SchedulerComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            viewType: ViewTypes.Day,
            startDate: new Date(), 
            endDate: new Date(),
            selectedEvent: null
        }
    }

    closeModal = (editMode) => {
        var selectedEvent = this.state.selectedEvent;
        if (editMode){
            selectedEvent.title = this.state.selectedEvent.other.originalBookingTitle;
        }
        else{
            let schedulerData = this.state.viewModel;
            schedulerData.removeEvent(this.state.selectedEvent);
            schedulerData.addEvent(this.state.selectedEvent);
            this.setState({
                viewModel: schedulerData
            })
            selectedEvent.other.newEvent = true;
        }
        this.setState({showModal: false});
    }

    render(){
        let modal = "";
        if (this.state.showModal)
        {
            modal = (<Bookcourtroom 
                    showModal={this.state.showModal}
                    viewType={this.state.viewType}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    closeModal={this.closeModal}
                    event={this.state.selectedEvent}
                    resource={this.state.selectedResource}
                    editMode={this.state.editMode}
                    ></Bookcourtroom>)
        }

        return (
            <div>
                <div>
                    <Scheduler schedulerData={this.props.viewModel}
                               prevClick={this.props.prevClick}
                               nextClick={this.props.nextClick}
                               onSelectDate={this.onSelectDate}
                               onViewChange={this.props.onViewChange}
                               eventItemClick={this.eventClicked}
                               viewEventClick={this.book}
                               viewEvent2Click={this.cancelNewBooking}
                               updateEventStart={this.updateEventStart}
                               updateEventEnd={this.updateEventEnd}
                               moveEvent={this.moveEvent}
                               newEvent={this.newEvent}
                               eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
                               eventItemTemplateResolver={eventItemTemplateResolver}
                    />
                    {modal}
                </div>
            </div>
        )
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        this.setState({
            viewModel: schedulerData
        })
    }

    book = (schedulerData, event) => {
        let resource = schedulerData.getResourceById(event.resourceId);
        if (event.other && event.other.newEvent){
            event.other.newEvent = false;
        }

        this.setState({
            showModal: true, 
            viewType: schedulerData.viewType, 
            startDate: schedulerData.startDate, 
            endDate: schedulerData.endDate,
            selectedEvent: event,
            selectedResource: resource,
            editMode: false,
        });
    };

    change = (schedulerData, event) => {
        let resource = schedulerData.getResourceById(event.resourceId);
        if (event.other && event.other.newEvent){
            event.other.newEvent = false;
            
        }
        event.other.originalBookingTitle = event.title;
        event.title = "Change booking";
        this.setState({
            showModal: true, 
            viewType: schedulerData.viewType, 
            startDate: schedulerData.startDate, 
            endDate: schedulerData.endDate,
            selectedEvent: event,
            selectedResource: resource,
            editMode: true,
        });
    }

    cancelNewBooking = (schedulerData, event) => {
        schedulerData.removeEvent(event);
        this.setState({viewModel: schedulerData});
    };

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {

        var dayOfWeekStart = moment(start).weekday();
        var dayOfWeekEnd = moment(end).weekday();
        var days = this.getDaysInView(start, end);
        // eslint-disable-next-line
        if (days === 0 || (dayOfWeekStart >= 1 && dayOfWeekStart <= 5) && (dayOfWeekEnd >= 1 && dayOfWeekEnd <= 5))
        {   //its a week selection, its valid.
            let newFreshId = 0;
            schedulerData.events.forEach((item) => {
                if(item.id >= newFreshId)
                    newFreshId = item.id + 1;
            });

            let newStart = start;
            let newEnd = end;
            if (schedulerData.viewType === ViewTypes.Week)
            {
                newStart = moment(start).format("YYYY-MM-DDT09:00:00");
                newEnd = moment(end).format("YYYY-MM-DDT17:00:00");
            }

            let newEvent = {
                id: newFreshId,
                title: "New booking",
                start: newStart,
                end: newEnd,
                resourceId: slotId,
                bgColor: "#0a7cb9",
                other: {newEvent: true, book: this.book, cancel: this.cancelNewBooking}
            }
            schedulerData.addEvent(newEvent);
            this.setState({
                viewModel: schedulerData
            })
        } 
    }

    updateEventStart = (schedulerData, event, newStart) => {
        var dayOfWeekStart = moment(newStart).weekday();
        var dayOfWeekEnd = moment(event.end).weekday();

        if ((dayOfWeekStart >= 1 && dayOfWeekStart <= 5) && (dayOfWeekEnd >= 1 && dayOfWeekEnd <= 5))
        {   //its a week selection, its valid.
            // eslint-disable-next-line
            newStart = newStart;
        }
        else if ((dayOfWeekStart === 0 && dayOfWeekEnd === 0) || (dayOfWeekStart === 6 && dayOfWeekEnd === 6))
        { //its a weekend only
            // eslint-disable-next-line
            newStart = newStart;
        }
        else
        {
            newStart = event.start;
        }

        schedulerData.updateEventStart(event, newStart);
        this.setState({
            viewModel: schedulerData
        })
    }

    updateEventEnd = (schedulerData, event, newEnd) => {
        var dayOfWeekStart = moment(event.start).weekday();
        var dayOfWeekEnd = moment(newEnd).weekday();

        if ((dayOfWeekStart >= 1 && dayOfWeekStart <= 5) && (dayOfWeekEnd >= 1 && dayOfWeekEnd <= 5))
        {   //its a week selection, its valid.
            // eslint-disable-next-line
            newEnd = newEnd;
        }
        else if ((dayOfWeekStart === 0 && dayOfWeekEnd === 0) || (dayOfWeekStart === 6 && dayOfWeekEnd === 6))
        { //its a weekend only
            // eslint-disable-next-line
            newEnd = newEnd;
        }
        else
        {
            newEnd = event.end;
        }

        schedulerData.updateEventEnd(event, newEnd);
        this.setState({
            viewModel: schedulerData
        })
    }

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        var days = this.getDaysInView(event.start, event.end);
        
        if (days > 0)
        {
            var dayOfWeekStart = moment(start).weekday();
            var dayOfWeekEnd = moment(end).weekday();

            if ((dayOfWeekStart >= 1 && dayOfWeekStart <= 5) && (dayOfWeekEnd >= 1 && dayOfWeekEnd <= 5))
            {   //its a week selection, its valid.
                // eslint-disable-next-line
                start = start;
                // eslint-disable-next-line
                end = end;
            }
            else
            {
                start = event.start;
                end = event.end;
            }
        }

        schedulerData.moveEvent(event, slotId, slotName, start, end);
        this.setState({
            viewModel: schedulerData
        })
    }

    getDaysInView = (start, end) => {
        var days = moment(end).diff(start, "days");
        
        return days;
    }
}

export default withDragDropContext(SchedulerComponent)
