import React, { Component }  from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';

class Menu extends Component{
    constructor(props) {
        super(props);

        this.state = {
            authenticated: false
        };
    }

    _canViewMyCourtrooms = () => {
        return this.props.isAuthenticatedUserResourceOwner;
    }

    _canViewMyBookings = () => {
        return this.props.isAuthenticatedUserResourceOwner || this.props.isAuthenticatedUserRequestor;
    }

    _canViewApprovals = () => {
        return this.props.isAuthenticatedUserResourceOwner;
    }

    _canViewFindCourtrooms = () => {
        return this.props.isAuthenticatedUserResourceOwner
        || this.props.isAuthenticatedUserRequestor;
    }

    _canViewCourtroomAdmin = () => {
        return this.props.isAuthenticatedUserSystemAdmin || this.props.isAuthenticatedUserResourceOwner;
    }

    _canViewFacilitiesAdmin = () => {
      return this.props.isAuthenticatedUserSystemAdmin;
  }

    componentDidMount = () => {
        
    }

    checkAuthentication() {
        this.props.auth.isAuthenticated().then(response => {
          let authenticated = response;
          if (authenticated !== this.state.authenticated) {
            this.setState({ authenticated });
            this.props.auth.getUser().then((user) => {
                this.props.updateStateForViewableLinks(user);
            });
          }
        });
      }
    
      componentDidMount = () => {
        this.checkAuthentication();
      }
    
      componentDidUpdate = () => {
        this.checkAuthentication();
      }
    
      componentWillUpdate = () => {
        this.checkAuthentication();
      }
    
      componentWillMount = () => {
        this.checkAuthentication();
      }

    render(){
        return (
            <ul className="au-link-list">
                {this._canViewMyCourtrooms() && <li className=""><Link to="/courtrooms">My Courtrooms</Link></li>}
                {this._canViewMyBookings() && <li className=""><Link to="/bookings">My Bookings</Link></li>}
                {this._canViewApprovals() && <li className=""><Link to="/approvals">Approvals</Link></li>}
                {this._canViewFindCourtrooms() && <li className=""><Link to="/findcourtrooms">Find Courtrooms</Link></li>}
                {this._canViewCourtroomAdmin() && <li className=""><Link to="/admin/courtrooms">Courtrooms</Link></li>}
                {this._canViewFacilitiesAdmin() && <li className=""><Link to="/admin/facilities">Facilities</Link></li>}
            </ul>
        )
    }
}

export default withAuth(Menu);