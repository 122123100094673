import React, { Component }  from 'react';
import { withAuth } from '@okta/okta-react';

class NoContent extends Component{
    constructor(props){
      super(props);
    }

    render(){
        return (
            <div className="au-body">
                <div className="container">
                <div className="row">
                        <div className="col-xs-12">
                            Could not find the content you are looking for.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAuth(NoContent);