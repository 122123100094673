import React, { Component }  from 'react';
import UserContext from '../../context/UserContext';
import { withAuth } from '@okta/okta-react';

class UserSelector extends Component{
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false
          };
    }

  checkAuthentication() {
    this.props.auth.isAuthenticated().then(response => {
      let authenticated = response;
      if (authenticated !== this.state.authenticated) {
        this.setState({ authenticated });
        this.context.updateUserContext(this.props.auth, authenticated);
      }
    });
  }

  logout = () => {
    this.props.auth.logout('/');
  }

  componentDidMount = () => {
    this.checkAuthentication();
  }

  componentDidUpdate = () => {
    this.checkAuthentication();
  }

  componentWillUpdate = () => {
    this.checkAuthentication();
  }

  componentWillMount = () => {
    this.checkAuthentication();
  }

    render(){
        return (
            <span>
            {this.state.authenticated && <button className="au-btn" onClick={this.logout}>Logout</button>}
            </span>
        )
    }
}
UserSelector.contextType = UserContext;
export default withAuth(UserSelector);