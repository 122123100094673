export async function fetchWithAuth(auth, url, options){
    let token = await auth.getAccessToken();
    let headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        };

    if (options === null || options === undefined) { 
        options = { headers: headers };
    }
    else 
    {
        options.headers = headers;
    }
    return fetch(url, options);
}