import "react-app-polyfill/ie11";
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link, Redirect, IndexRoute, Switch } from "react-router-dom";
import FindCourtrooms from "./components/FindCourtrooms/FindCourtrooms";
import AdminCourtrooms from "./components/AdminCourtrooms/AdminCourtrooms";
import AdminCourtroomById from "./components/AdminCourtrooms/AdminCourtroomById";
import AdminFacilityById from "./components/AdminFacilities/AdminFacilityById";
import AdminFacilities from "./components/AdminFacilities/AdminFacilities";
import Courtrooms from "./components/Home/Courtrooms";
import Bookings from "./components/Home/Bookings";
import UserContext from "./context/UserContext";
import UserSelector from "./components/UserSelector/UserSelector";
import Approvals from "./components/Approvals/Approvals";
import { Security, SecureRoute, ImplicitCallback} from "@okta/okta-react";
import _ from "lodash";
import Menu from "./components/Menu/Menu";
import NoContent from "./components/Home/NoContent";
import NotSystemAdmin from "./components/Home/NotSystemAdmin";

class App extends React.Component {
  constructor(props) {
    super(props);
    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      user: {email: "owner@justice.nsw.gov.au", groups: [], userId: "0"},
      changeUser: this.changeUser,
      updateUserContext: this.updateUserContext,
      accessToken: null,
      idToken: null
    };
  }
  //might not need this anymore if we can get access to props.auth from there. or create a menu component!
  updateUserContext = (auth, authenticated) => {
    if (authenticated === true){   
      auth.getUser().then(user => {
        this.setState({user: {
            email: user.email,
            name: user.name,
            groups: user.groups,
            userId: user.sub,
            userInfo: user,
            isAuthenticatedUserResourceOwner: false,
            isAuthenticatedUserRequestor: false,
            isAuthenticatedUserSystemAdmin: false,
            }});
      });
    }
  };

  _doesUserHaveGroupAccess = (user, groupName) => {
    return user && _.findIndex(user.groups, 
        function(group) { return group.toLowerCase() === groupName.toLowerCase(); }) > -1;
}

  _updateStateForViewableLinks = (user) => {
    
    this.setState({
        isAuthenticatedUserResourceOwner: this._doesUserHaveGroupAccess(user, process.env.REACT_APP_RESOURCE_OWNER_GROUP_NAME),
        isAuthenticatedUserRequestor: this._doesUserHaveGroupAccess(user, process.env.REACT_APP_REQUESTOR_GROUP_NAME),
        isAuthenticatedUserSystemAdmin: this._doesUserHaveGroupAccess(user, process.env.REACT_APP_SYSTEM_ADMINISTRATOR_OWNER_GROUP_NAME),
    });
  }

  render() {
    let redirectToBookings = this.state.isAuthenticatedUserResourceOwner === false && this.state.isAuthenticatedUserRequestor === true;
  
    return (
      <Router>
        <Security issuer={process.env.REACT_APP_ISSUER_URL}
                            clientId={process.env.REACT_APP_CLIENT_ID}
                            redirectUri={window.location.origin + '/callback'}>
        
      <div>
        <nav className="au-main-nav au-main-nav--dark">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                <div className="au-main-nav__content">
                  <button
                    className="au-main-nav__toggle au-main-nav__toggle--open">
                    Menu
                  </button>
                  <div className="au-main-nav__menu">
                    <div className="au-main-nav__menu-inner">
                      <div className="au-main-nav__focus-trap-top"></div>
                      <button
                        className="au-main-nav__toggle au-main-nav__toggle--close">
                        Close
                      </button>
                      
                        <Menu updateStateForViewableLinks={this._updateStateForViewableLinks} 
                            isAuthenticatedUserResourceOwner={this.state.isAuthenticatedUserResourceOwner}
                            isAuthenticatedUserRequestor={this.state.isAuthenticatedUserRequestor}
                            isAuthenticatedUserSystemAdmin={this.state.isAuthenticatedUserSystemAdmin}/>
                      
                      <div className="au-main-nav__focus-trap-bottom"></div>
                    </div>
                  </div>
                  <div className="au-main-nav__overlay"></div>
                </div>
              </div>
              <div className="col-md-2">
                <ul className="au-link-list">
                  <li className="">
                    <UserContext.Provider value={this.state}>
                      <UserSelector/>
                    </UserContext.Provider>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        
        <UserContext.Provider value={this.state}>
            <Switch>
              <Route exact path="/" render={() => (
                redirectToBookings ? (
                  <SecureRoute path="/" exact component={Bookings} />
                ) : (
                  <SecureRoute path="/" exact component={Courtrooms} />
                )
              )}/>
              <SecureRoute path="/bookings" exact component={Bookings} />
              {this.state.isAuthenticatedUserResourceOwner && <SecureRoute path="/courtrooms" exact component={Courtrooms} />}
              {this.state.isAuthenticatedUserResourceOwner && <SecureRoute path="/approvals" exact component={Approvals} />}
              <SecureRoute path="/findcourtrooms" component={FindCourtrooms} />
              {(this.state.isAuthenticatedUserResourceOwner || this.state.isAuthenticatedUserSystemAdmin) && <SecureRoute path="/admin/courtrooms" exact component={AdminCourtrooms} />}
              {(this.state.isAuthenticatedUserResourceOwner || this.state.isAuthenticatedUserSystemAdmin) && <SecureRoute path="/admin/facilities" exact component={AdminFacilities} />}
              {(this.state.isAuthenticatedUserSystemAdmin === false) && <SecureRoute path="/admin/courtrooms/new/" exact component={NotSystemAdmin} />}
              {(this.state.isAuthenticatedUserResourceOwner || this.state.isAuthenticatedUserSystemAdmin) && <SecureRoute path="/admin/courtrooms/:id/" exact component={AdminCourtroomById} />}
              {(this.state.isAuthenticatedUserResourceOwner || this.state.isAuthenticatedUserSystemAdmin) && <SecureRoute path="/admin/facilities/:id/" exact component={AdminFacilityById} />}
              <Route path='/callback' component={ImplicitCallback} />
              <SecureRoute component={NoContent} />
            </Switch>
        </UserContext.Provider>
      </div>
      </Security>
    </Router>
    );
  }
}

export default App;
