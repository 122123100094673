import React, { Component }  from 'react';
import ChangeBookingsGrid from '../Common/ChangeBookingsGrid';
import UserContext from '../../context/UserContext';
import moment from 'moment';
import { withAuth } from '@okta/okta-react';
import {fetchWithAuth} from '../../common/FetchUtil';

import './Bookings.css'

class Bookings extends Component{
    constructor(props){
      super(props);
      this.state = {
          approvedBookings: [],
          approvedBookingsSkip: 0,
          approvedBookingsTake: 10,
          tentativeBookings: [],
          tentativeBookingsSkip: 0,
          tentativeBookingsTake: 10,
      }
    }

    _fetchMyApprovedBookings = (skip, take) => {
        let pageNumber = (skip / take) + 1;
        let pageSize = take;

        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/users/bookings/approved?pageNumber="+pageNumber+"&pageSize="+pageSize+"&startDate="+moment(new Date()).format("YYYY-MM-DDT00:00:00"))
        .then(response => response.json())
        .then(json => {
            let bookings = [];
            json.items.forEach(function(booking){
                let jurisdiction = "Other";

                if (booking.jurisdictionId) {
                    jurisdiction = booking.jurisdiction.name;
                }
                bookings.push({
                    id: booking.id,
                    date:moment(booking.startDate).format("DD/MM/YYYY"),
                    startTime: moment(booking.startDate).format("h:mm a"),
                    endTime: moment(booking.endDate).format("h:mm a"),
                    courthouse: booking.courthouse.name,
                    courtroom: booking.courtroom.name,
                    status: booking.bookingStatusDescription,
                    jurisdiction: jurisdiction,
                    booking: booking
                })
            });
            this.setState({approvedBookings: bookings, approvedBookingsTotalItems: json.totalItems});
        });
    }

    _fetchMyTentativeBookings = (skip, take) => {
        let pageNumber = (skip / take) + 1;
        let pageSize = take;

        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/users/bookings/tentative?pageNumber="+pageNumber+"&pageSize="+pageSize)
        .then(response => response.json())
        .then(json => {

            let bookings = [];
            json.items.forEach(function(booking){
                let jurisdiction = "Other";

                if (booking.jurisdictionId) {
                    jurisdiction = booking.jurisdiction.name;
                }
                bookings.push({
                    id: booking.id,
                    date:moment(booking.startDate).format("DD/MM/YYYY"),
                    startTime: moment(booking.startDate).format("h:mm a"),
                    endTime: moment(booking.endDate).format("h:mm a"),
                    courthouse: booking.courthouse.name,
                    courtroom: booking.courtroom.name,
                    status: booking.bookingStatusDescription,
                    jurisdiction: jurisdiction,
                    booking: booking
                })
            });
            this.setState({tentativeBookings: bookings, tentativeBookingsTotalItems: json.totalItems});
        });
    }

    componentDidMount(){
        this._fetchMyApprovedBookings(this.state.approvedBookingsSkip, this.state.approvedBookingsTake);
        this._fetchMyTentativeBookings(this.state.tentativeBookingsSkip, this.state.tentativeBookingsTake);
    }

    approvedPageChange = (event) => {
        this.setState({
            approvedBookingsSkip: event.page.skip,
            approvedBookingsTake: event.page.take
        });
        this._fetchMyApprovedBookings(event.page.skip, event.page.take);
    }

    tentativePageChange = (event) => {
        this.setState({
            tentativeBookingsSkip: event.page.skip,
            tentativeBookingsTake: event.page.take,
            tentativeBookings: []
        });
        this._fetchMyTentativeBookings(event.page.skip, event.page.take);
    }

    render(){
        return (
            <div className="au-body">
                <div className="container">
                <div className="row">
                        <div className="col-xs-12">
                            <ChangeBookingsGrid 
                                title={"My Bookings pending approval"}
                                pageSize={10} 
                                pageChange={this.tentativePageChange}
                                totalItems={this.state.tentativeBookingsTotalItems}
                                skip={this.state.tentativeBookingsSkip}
                                take={this.state.tentativeBookingsTake}
                                data={this.state.tentativeBookings}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <ChangeBookingsGrid 
                                title={"My Approved bookings"}
                                pageSize={10} 
                                pageChange={this.approvedPageChange}
                                totalItems={this.state.approvedBookingsTotalItems}
                                skip={this.state.approvedBookingsSkip}
                                take={this.state.approvedBookingsTake}
                                data={this.state.approvedBookings}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Bookings.contextType = UserContext;

export default withAuth(Bookings);