import React, { Component }  from 'react';
import moment from 'moment';
import FilterCriteria from './FilterCriteria/FilterCriteria'
import SchedulerComponent from './scheduler/scheduler';
import {SchedulerData, ViewTypes} from 'react-big-scheduler'
import {getDateLabel, globalSchedulerConfig} from '../../common/SchedulerConfig';
import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from '../../common/FetchUtil';

const NO_RESULTS_FOUND = "No results found.";
const SEARCHING_FOR_COURTROOMS = "Searching for courtrooms....";
const NOT_ENOUGH_SEARCH_CRITERIA_USED = "Not enough filter criteria used. Please select at least one Region, Hub, Courthouse or Courtroom.";
const DONE_SEARCHING_FOR_COURTROOMS = "Searching for courtrooms....done!";

class FindCourtrooms extends Component{
    constructor(props){
      super(props);
      this.child = React.createRef();
      let schedulerData = new SchedulerData(
            new Date(), 
            ViewTypes.Day, 
            false, 
            false, 
            globalSchedulerConfig(),
            {
                getDateLabelFunc: getDateLabel,
            }
        );

        this.state = {
          viewModel: schedulerData,
          errorMessage: " ",
          resources: []
      }
    }

    _getQueryParameter = (prefix, list) => {
        
        if (list){ 
            return prefix+""+list.join(",") + "&"; 
        }
        else{
            return "";
        }
    }

    _change = (schedulerData, event) => {
        this.child.current.ref.current.change(schedulerData, event);
    }

    _getBookings = (resources) => {
        resources.forEach((resource) => {
            fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/courtrooms/" + resource.id + "/bookings?startDate="+moment(this.state.viewModel.startDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")+"&endDate="+moment(this.state.viewModel.endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"))
                .then(response => response.json())
                .then(json => {
                    
                    let viewModel = this.state.viewModel;
                    json.forEach((booking) => {
                        let bookingTitle = "[Other] " + booking.bookingStatusDescription;

                        if (booking.jurisdictionId)
                            bookingTitle = "["+booking.jurisdiction.name+"] " + booking.bookingStatusDescription;

                        viewModel.addEvent({id: booking.id, 
                            start: booking.startDate, 
                            end: booking.endDate, 
                            resourceId: booking.courtroomId, 
                            title: bookingTitle, 
                            movable: false,
                            resizable: false,
                            other: {
                                editableEvent: true,
                                change: this._change,
                                booking: booking,
                            }
                        });
                    });
                    
                    this.setState({ 
                        viewModel: viewModel
                    });
                });
        })
        
    }

    searchForCourtrooms = data => {
        
        var regionsQuery = this._getQueryParameter("regions=", data.selectedRegions);
        var hubsQuery = this._getQueryParameter("hubs=", data.selectedHubs);
        var courthousesQuery = this._getQueryParameter("courthouses=", data.selectedCourthouses);
        var courtroomsQuery = this._getQueryParameter("courtrooms=", data.selectedCourtrooms);
        var jurisdictionsQuery = this._getQueryParameter("jurisdictions=", data.selectedJurisdictions);
        var facilitiesQuery = this._getQueryParameter("facilities=", data.selectedFacilities); 

        if (data.selectedRegions.length === 0  
            && data.selectedHubs.length === 0 
            && data.selectedCourthouses.length === 0 
            && data.selectedCourtrooms.length === 0){
            this.setState({ errorMessage: NOT_ENOUGH_SEARCH_CRITERIA_USED});
            return;
        }
        else
        {
            this.setState({ errorMessage: SEARCHING_FOR_COURTROOMS });
            
            fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/courtrooms/availability?" + regionsQuery + hubsQuery + courthousesQuery + courtroomsQuery + jurisdictionsQuery + facilitiesQuery)
            .then(response => response.json())
            .then(json => {
                this.setState({ errorMessage: DONE_SEARCHING_FOR_COURTROOMS });
                var viewModel = this.state.viewModel;
                viewModel.resources = [];
                var retrievedResources = [];
                json.forEach(function(resource){
                    retrievedResources.push({id: resource.id, name: resource.name});
                });
                
                if (moment(data.startDate).isSame(data.endDate, "day") === false && viewModel.viewType !== ViewTypes.Week) {
                    viewModel.setViewType(ViewTypes.Week, false, false);
                }
                else if (moment(data.startDate).isSame(data.endDate, "day") === true &&  viewModel.viewType !== ViewTypes.Day) {
                    viewModel.setViewType(ViewTypes.Day, false, false);
                }
                
                viewModel.setDate(moment(data.startDate));
                
                viewModel.setResources(retrievedResources);
                this.setState({
                    viewModel: viewModel,
                    errorMessage: viewModel.resources.length === 0 ? NO_RESULTS_FOUND : null,
                    resources: retrievedResources
                });

                this._getBookings(retrievedResources);
            })
        }
    }

    _onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        this.setState({
            viewModel: schedulerData
        })
        this._getBookings(this.state.resources);
    }

    _prevClick = (schedulerData)=> {
        schedulerData.prev();
        this.setState({
            viewModel: schedulerData
        })
        this._getBookings(this.state.resources);
    }

    _nextClick = (schedulerData)=> {
        schedulerData.next();
        this.setState({
            viewModel: schedulerData
        })
        this._getBookings(this.state.resources);
    }

    render(){
        let element;
        if (this.state.errorMessage) {
            element = (
                <div className="au-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                {this.state.errorMessage}
                            </div>
                        </div>
                    </div>
                </div>);
        } else {
            element = (<div className="au-body">
                <SchedulerComponent
                    ref={this.child} 
                    viewModel={this.state.viewModel} 
                    prevClick={this._prevClick} 
                    nextClick={this._nextClick}
                    onViewChange={this._onViewChange}/>
            </div>);
        }

        return (
        <main id="content">
            <div className="au-body au-body--alt">
                <FilterCriteria searchButtonClicked={this.searchForCourtrooms}/>
            </div>
            {element}
        </main>
        )}
}

export default withAuth(FindCourtrooms);