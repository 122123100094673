import React, { Component }  from "react";
import { Grid, GridColumn as Column, GridToolbar  } from "@progress/kendo-react-grid";
import { withAuth } from "@okta/okta-react";
import { fetchWithAuth } from "../../common/FetchUtil";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import "@progress/kendo-theme-default/dist/all.css";
import "./AdminFacilities.css";

const SEARCHING_FOR_FACILITIES = "Searching for Facilities....";

class AdminFacilities extends Component{
    constructor(props){
      super(props);

      this.state = {
        facilities: [],
        errorMessage: SEARCHING_FOR_FACILITIES,
        skip: 0,
        take: 10,
        dialogVisible: false
      }
    }

    _toggleDialog = (facility) => {
        this.setState({
            dialogVisible: !this.state.dialogVisible,
            selectedFacility: facility,
        });
    }

    _closeDialog = () => {
        this.setState({
            dialogVisible: false,
            showErrorMessage: false,
            message: "",
        });
    }

    _pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    }

    _deleteFacility = (facility) => {
        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/facilities/" + facility.id,
            {
                method: 'DELETE'
            })
        .then(response => response.json())
        .then(json => {
            if (json.errorCode !== -1)
            {
                this.setState({
                    showErrorMessage: true,
                    showSuccessMessage: false,
                    message: json.message
                });
            }
            else 
            {
                this.setState({
                    showErrorMessage: false,
                    showSuccessMessage: true,
                    message: json.message
                });
                this._closeDialog();
                this._fetchFacilities();
            }
        }).catch((error) => {
            this.setState({
                showErrorMessage: true,
                showSuccessMessage: false,
                message: "There was an error deleting the facility."
            });
        });
    }

    _fetchFacilities = () => {
        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/facilities")
        .then(response => response.json())
        .then(json => this.setState({ facilities:  json, errorMessage: null}));
    }

    componentDidMount = () => {
        this._fetchFacilities();
    }

    render(){
        let element;
        if (this.state.errorMessage) {
            element = this.state.errorMessage;
        } else {
            element = 
                (
                    <Grid data={this.state.facilities.slice(this.state.skip, this.state.take + this.state.skip)}
                         skip={this.state.skip}
                         take={this.state.take}
                         total={this.state.facilities.length}
                         pageable={true}
                         onPageChange={this._pageChange}>
                             <GridToolbar>
                                <Link className="au-btn au-btn--secondary" to={"/admin/facilities/new"}>Add new facility</Link>
                            </GridToolbar> 
                        <Column field="name" title="Name"/>
                        <Column field="description" title="Description"/>
                        <Column title="" width="100px"
                            cell={(props) => (
                                <td>
                                    <Link className="au-btn au-btn--tertiary" to={"/admin/facilities/"+props.dataItem.id+"/"}>Change</Link>
                                </td>
                            )} />
                        <Column title="" width="100px"
                            cell={(props) => (
                                <td>
                                    <button className="au-btn au-btn--tertiary" onClick={this._toggleDialog.bind(this, props.dataItem)}>Delete</button>
                                </td>
                            )} />
                        </Grid>
                );
        }
       
        return (
        <main id="content">
            <div className="au-body">
                <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <h1>Manage Courtroom facilities</h1>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-xs-12">
                            {element}
                            {this.state.dialogVisible && <Dialog title={"Confirm delete"} onClose={this._toggleDialog}>
                                <span>
                                <p style={{ margin: "25px", marginBottom: "0px", textAlign: "center" }}>This record will be permanently deleted.</p>
                                <p style={{ margin: "25px", marginTop: "0px",textAlign: "center" }}>Are you sure you want to continue?</p></span>
                                <DialogActionsBar>
                                        <button className="au-btn au-btn--secondary au-btn-margin-left" onClick={this._closeDialog}>No</button>
                                        <button className="au-btn au-btn--primary au-btn-margin-left" onClick={this._deleteFacility.bind(this, this.state.selectedFacility)}>Yes</button>
                                </DialogActionsBar>
                            </Dialog>}
                        </div>
                    </div>
                </div>
            </div>
        </main>)}
}

export default withAuth(AdminFacilities);