import React, { Component }  from 'react';
import { Grid, GridColumn as Column, GridToolbar  } from '@progress/kendo-react-grid';
import FilterCriteria from '../FindCourtrooms/FilterCriteria/FilterCriteria';
import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from '../../common/FetchUtil';
import _ from 'lodash';
import CustomHeader from './CustomHeader';
import { ExcelExport } from '@progress/kendo-react-excel-export';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import './AdminCourtrooms.css';

const NO_RESULTS_FOUND = "No results found.";
const SEARCHING_FOR_COURTROOMS = "Searching for courtrooms....";
const NOT_ENOUGH_SEARCH_CRITERIA_USED = "Not enough filter criteria used. Please select at least one Region, Hub, Courthouse or Courtroom.";
const DONE_SEARCHING_FOR_COURTROOMS = "Searching for courtrooms....done!";

class AdminCourtrooms extends Component{
    constructor(props){
      super(props);

      this.state = {
        errorMessage: " ",
        enableSearchButton: false,
        skip: 0,
        take: 10
      }
    }

    _getQueryParameter = (prefix, list) => {
        
        if (list){ 
            return prefix+""+list.join(",") + "&"; 
        }
        else{
            return "";
        }
    }

    _pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    }

    searchForCourtrooms = data => {
        
        var regionsQuery = this._getQueryParameter("regions=", data.selectedRegions);
        var hubsQuery = this._getQueryParameter("hubs=", data.selectedHubs);
        var courthousesQuery = this._getQueryParameter("courthouses=", data.selectedCourthouses);
        var courtroomsQuery = this._getQueryParameter("courtrooms=", data.selectedCourtrooms);
        var jurisdictionsQuery = this._getQueryParameter("jurisdictions=", data.selectedJurisdictions);
        var facilitiesQuery = this._getQueryParameter("facilities=", data.selectedFacilities); 

        if (data.selectedRegions.length === 0  
            && data.selectedHubs.length === 0 
            && data.selectedCourthouses.length === 0 
            && data.selectedCourtrooms.length === 0){
            this.setState({ errorMessage: NOT_ENOUGH_SEARCH_CRITERIA_USED});
            return;
        }
        else
        {
            this.setState({ errorMessage: SEARCHING_FOR_COURTROOMS });
            
            fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/courtrooms/availability?" + regionsQuery + hubsQuery + courthousesQuery + courtroomsQuery + jurisdictionsQuery + facilitiesQuery)
            .then(response => response.json())
            .then(json => {
                this.setState({ errorMessage: DONE_SEARCHING_FOR_COURTROOMS });
                
                let courtrooms = [];
                json.forEach((courtroom) => {
                    var data = {
                        "id": courtroom.id,
                        "Name": courtroom.name,
                        "Booking Status": courtroom.isAvailableForBooking ? "Available" : "Not available",
                    };

                    this.state.facilities.forEach(function(facility){
                        var courtroomFacility = _.find(courtroom.courtroomFacilities, function(cf) { return cf.facilityId === facility.id} );
                        data[facility.name] = courtroomFacility && courtroomFacility.amount ? courtroomFacility.amount : courtroomFacility ? "Y" : "";
                    });

                    data["Notes"] = courtroom.notes;

                    courtrooms.push(data);
                });

                this.setState({
                    errorMessage: json.length === 0 ? NO_RESULTS_FOUND : null,
                    courtrooms: courtrooms,
                });
            })
        }
    }

    componentDidMount = () => {
        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/facilities")
        .then(response => response.json())
        .then(json => this.setState({ facilities:  json}))
        .then(() => {
        fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/users/favourites")
            .then(response => response.json())
            .then(json => {
            var facilities = _.intersectionWith(this.state.facilities, json, function(v1, v2){
                return v1.value === v2.facilityId;
            })
            this.setState({selectedFacilities: facilities,
                enableSearchButton: true,
                });
            })
        })
    }

    _export;
    export = () => {
        this._export.save();
    }

    render(){
        let element;
        if (this.state.errorMessage) {
            element = this.state.errorMessage;
        } else {
            var allColumns = this.state.courtrooms.length > 0 ? Object.keys(this.state.courtrooms[0]) : []
            var columnsToShow = allColumns.map((item,i) => {
                if (item === "id"){
                    return "";
                }
                if (item === "Name" || item === "Booking Status"){
                    return (<Column field={item} key={i} width="120px"/>);
                }
                else if (item === "Notes"){
                    return (<Column field={item} key={i}/>);
                }
                else{
                    return (<Column field={item} key={i} headerCell={CustomHeader} width="40px"/>);
                }
            });
            element = 
                (
                    <Grid data={this.state.courtrooms.slice(this.state.skip, this.state.take + this.state.skip)} className="allow-vertical-columns"
                         skip={this.state.skip}
                         take={this.state.take}
                         total={this.state.courtrooms.length}
                         pageable={true}
                         onPageChange={this._pageChange}>
                            <GridToolbar>
                                <button
                                    title="Export Excel"
                                    className="au-btn au-btn--secondary"
                                    onClick={this.export}>Export to Excel</button>
                            </GridToolbar>
                        {columnsToShow}
                        <Column title="Action" width="100px"
                            cell={(props) => (
                                <td>
                                    <Link className="au-btn au-btn--tertiary" to={"/admin/courtrooms/"+props.dataItem.id+"/"}>Change</Link>
                                </td>
                            )} /> 
                        </Grid>
                );
        }

        
        return (
        <main id="content">
            <div className="au-body au-body--alt">
                <FilterCriteria 
                searchButtonClicked={this.searchForCourtrooms} 
                searchEnabled={!this.state.enableSearchButton}
                addEntity={(<Link className="au-btn au-btn--secondary" to={"/admin/courtrooms/new"}>Add new courtroom</Link>
                    )}/>
            </div>

            <div className="au-body">
                    <ExcelExport
                        data={this.state.courtrooms}
                        ref={exporter => this._export = exporter}
                    >
                    {element}
                    </ExcelExport>
                </div>
        </main>)}
}

export default withAuth(AdminCourtrooms);