import React, { Component }  from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import intersectWith from 'lodash/intersectionWith';

import RegionSelect from './RegionSelect';
import HubSelect from './HubSelect';
import CourthouseSelect from './CourthouseSelect';
import JurisdictionSelect from './JurisdictionSelect';
import CourtroomSelect from './CourtroomSelect';
import FacilitySelect from './FacilitySelect';
import SearchCourtrooms from './SearchCourtrooms';
import AddToFavourites from './AddToFavourites';
import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from '../../../common/FetchUtil';

import './FilterCriteria.css';
import "react-datepicker/dist/react-datepicker.css";

const today = new Date();
const startOfWeek = new Date(moment(today).startOf("week").format("MM/DD/YYYY 08:00:00"));
const endOfWeek = new Date(moment(today).endOf("week").format("MM/DD/YYYY 17:00:00"));

const END_DATE_MUST_BE_WITHIN_THE_SELECTED_WEEK = "The end date should be within the week.";
const END_DATE_MUST_BE_AFTER_THE_START_DATE = "The end time should be after start time.";

class FilterCriteria extends Component{
  constructor(props){
    super(props);
    this.state = { 
      regions: [],
      hubs: [],
      courthouses: [],
      jurisdictions: [],
      courtrooms: [],
      facilities: [],
      selectedRegions: [],
      selectedHubs: [],
      selectedCourthouses: [],
      selectedJurisdictions: [],
      selectedCourtrooms: [],
      selectedFacilities: [],
      startDate: startOfWeek,
      endDate: endOfWeek,
      startOfWeek: startOfWeek,
      endOfWeek: endOfWeek,
      endDateErrorMessage: null,
      values: []
    };
    this.mapToDropdownItems = this.mapToDropdownItems.bind(this);
  }
  
  mapToDropdownItems = json => {
    var items = json.map(function(entry) {
      var item = {"value": entry.id, "label": entry.name, "original": entry };
      return item;
    });
    return items;
  };
  
  regionsChanged = selectedOptions => {
    this.setState({ values: selectedOptions});
    this.setState({ selectedRegions:  selectedOptions})
  };
  
  hubsChanged = selectedOptions => {
    this.setState({ selectedHubs:  selectedOptions})
  };
  
  courthousesChanged = selectedOptions => {
    this.setState({ selectedCourthouses:  selectedOptions})
  };
  
  jurisdictionsChanged = selectedOptions => {
    this.setState({ selectedJurisdictions:  selectedOptions})
  };

  courtroomsChanged = selectedOptions => {
    this.setState({ selectedCourtrooms:  selectedOptions})
  };

  facilitiesChanged = selectedOptions => {
    this.setState({ selectedFacilities:  selectedOptions})
  };

  startDateChanged = newDate => {
    
    var startOfWeek = new Date(moment(newDate).startOf("isoWeek").subtract(1, "day").format("MM/DD/YYYY 08:00:00"));
    var endOfWeek = new Date(moment(newDate).endOf("week").format("MM/DD/YYYY 17:00:00"));

    this.setState({ 
      startDate: newDate, 
      startOfWeek: startOfWeek,
      endOfWeek: endOfWeek});
  };

  endDateChanged = newDate => {
    this.setState({ endDate: newDate}, () => this.validateEndDate());
  };

  componentDidMount = () => {
    fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/regions")
    .then(response => response.json())
    .then(json => this.setState({ regions:  this.mapToDropdownItems(json)}))
    .then(() => {
      fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/users/favourites")
    .then(response => response.json())
    .then(json => {
      var regions = intersectWith(this.state.regions, json, function(v1, v2){
        return v1.value === v2.regionId;
      })
      this.setState({selectedRegions: regions});
      })
    })
    
    fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/hubs")
    .then(response => response.json())
    .then(json => this.setState({ hubs:  this.mapToDropdownItems(json)}))
    .then(() => {
      fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/users/favourites")
                .then(response => response.json())
                .then(json => {
              var hubs = intersectWith(this.state.hubs, json, function(v1, v2){
                return v1.value === v2.hubId;
              })
              this.setState({selectedHubs: hubs});
              })
    })

    fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/courthouses")
    .then(response => response.json())
    .then(json => this.setState({ courthouses:  this.mapToDropdownItems(json)}))
    .then(() => {
      fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/users/favourites")
              .then(response => response.json())
              .then(json => {
            var courthouses = intersectWith(this.state.courthouses, json, function(v1, v2){
              return v1.value === v2.courthouseId;
            })
            this.setState({selectedCourthouses: courthouses});
            })
    })

    fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/jurisdictions")
    .then(response => response.json())
    .then(json => this.setState({ jurisdictions:  this.mapToDropdownItems(json)}))
    .then(() => {
      fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/users/favourites")
          .then(response => response.json())
          .then(json => {
        var jurisdictions = intersectWith(this.state.jurisdictions, json, function(v1, v2){
          return v1.value === v2.jurisdictionId;
        })
        this.setState({selectedJurisdictions: jurisdictions});
        })
    })

    fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/courtrooms")
    .then(response => response.json())
    .then(json => this.setState({ courtrooms:  this.mapToDropdownItems(json)}))
    .then(() => {
      fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/users/favourites")
        .then(response => response.json())
        .then(json => {
          var courtrooms = intersectWith(this.state.courtrooms, json, function(v1, v2){
            return v1.value === v2.courtroomId;
          })
          this.setState({selectedCourtrooms: courtrooms});
          })
    });
    

    fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/facilities")
    .then(response => response.json())
    .then(json => this.setState({ facilities:  this.mapToDropdownItems(json)}))
    .then(() => {
      fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/users/favourites")
        .then(response => response.json())
        .then(json => {
          var facilities = intersectWith(this.state.facilities, json, function(v1, v2){
            return v1.value === v2.facilityId;
          })
          this.setState({selectedFacilities: facilities});
          })
    })
    
  }

  _isEndDateWithinTheSelectedWeek = () => {
    return (this.state.endDate < this.state.startDate || this.state.endDate > this.state.endOfWeek)
  }

  _isEndTimeBeforeStartTime = () => {
    return moment(this.state.startDate).isSame(this.state.endDate, "day") && moment(this.state.endDate).isBefore(this.state.startDate, "hour");
  }

  _isEndDateNotValid = () =>{
    return this._isEndDateWithinTheSelectedWeek() || this._isEndTimeBeforeStartTime();
  }

  validateEndDate = () => {
    if (this._isEndTimeBeforeStartTime()){
      this.setState({endDateErrorMessage: END_DATE_MUST_BE_AFTER_THE_START_DATE}); 
      return;
    }
    
    if (this._isEndDateWithinTheSelectedWeek()){
      this.setState({endDateErrorMessage: END_DATE_MUST_BE_WITHIN_THE_SELECTED_WEEK}); 
      return;
    }
    
      this.setState({endDateErrorMessage: null});
    
  }

  searchButtonClicked = () => {
    this.validateEndDate();

    if (this._isEndDateNotValid() === true)
      return;

    var selectedRegions = [];
    var selectedHubs = [];
    var selectedCourthouses = [];
    var selectedJurisdictions = [];
    var selectedCourtrooms = [];
    var selectedFacilities = [];

    if (this.state.selectedRegions){
      selectedRegions = this.state.selectedRegions.map(function(entry) { return entry.value });
    }

    if (this.state.selectedHubs){
      selectedHubs = this.state.selectedHubs.map(function(entry) { return entry.value });
    }

    if (this.state.selectedCourthouses){
      selectedCourthouses = this.state.selectedCourthouses.map(function(entry) { return entry.value });
    }

    if (this.state.selectedJurisdictions){
      selectedJurisdictions = this.state.selectedJurisdictions.map(function(entry) { return entry.value });
    }

    if (this.state.selectedCourtrooms){
      selectedCourtrooms = this.state.selectedCourtrooms.map(function(entry) { return entry.value });
    }

    if (this.state.selectedFacilities){
      selectedFacilities = this.state.selectedFacilities.map(function(entry) { return entry.value });
    }

    var data = { 
      selectedRegions: selectedRegions,
      selectedHubs: selectedHubs,
      selectedCourthouses: selectedCourthouses,
      selectedJurisdictions: selectedJurisdictions,
      selectedCourtrooms: selectedCourtrooms,
      selectedFacilities: selectedFacilities,
      startDate: this.state.startDate,
      endDate: this.state.endDate
     };
    this.props.searchButtonClicked(data);
  } 

  render(){
      let endDateErrorMessage = ""

      if (this.state.endDateErrorMessage){
        endDateErrorMessage = (<label style={{color: "#B81237"}}>{this.state.endDateErrorMessage}</label>);
      }


      return (
        <div className="container">
        <div className="row">
          <div className="col-xs-6">
            <RegionSelect state={this.state} handleChange={this.regionsChanged}/>
          </div>
          <div className="col-xs-6">
            <HubSelect state={this.state} handleChange={this.hubsChanged}/>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <CourthouseSelect state={this.state} handleChange={this.courthousesChanged}/>
          </div>
          <div className="col-xs-6">
            <JurisdictionSelect state={this.state} handleChange={this.jurisdictionsChanged}/>
          </div>
        </div>
        <div className="row">
        <div className="col-xs-12">
            <FacilitySelect 
             state={this.state}
             handleChange={this.facilitiesChanged}/>
          </div>
        </div>
        <div className="row">
        <div className="col-xs-12">
            <CourtroomSelect 
             state={this.state}
             selectedCourthouses={this.state.selectedCourthouses} 
             handleChange={this.courtroomsChanged}/>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <div className="form-item">
	            <label className="label--block">Start Date</label>
              <DatePicker
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.startDateChanged}
                showTimeSelect
                timeFormat="h:mm aa"
                timeIntervals={60}
                dateFormat="dd/MM/yyyy h:mm aa"
                timeCaption="Time"
                className="au-text-input"/>
                
            </div>
          </div>
          <div className="col-xs-6">
            <div className="form-item">
	            <label className="label--block">End Date</label>
              <DatePicker
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.endDateChanged}
                minDate={this.state.startDate}
                maxDate={this.state.endOfWeek}
                showTimeSelect
                timeFormat="h:mm aa"
                timeIntervals={60}
                dateFormat="dd/MM/yyyy h:mm aa"
                timeCaption="Time"
                className="au-text-input"/>
                {endDateErrorMessage}
            </div>
          </div>
        </div>
        <div className="row">
            <div className="col-xs-3">
                <SearchCourtrooms searchButtonClicked={this.searchButtonClicked} searchEnabled={this.props.searchEnabled} />
                </div>
            <div className="col-xs-3">
                
            </div>
            <div className="col-xs-3">
                <div className="form-item" style={{marginTop: 2.5 + 'rem'}}>
                  {this.props.addEntity}
                </div>
            </div>
            <div className="col-xs-3">
              <AddToFavourites selectedCourthouses={this.state.selectedCourthouses} 
                        selectedRegions={this.state.selectedRegions}
                        selectedHubs={this.state.selectedHubs}
                        selectedCourtrooms={this.state.selectedCourtrooms}
                        selectedJurisdictions={this.state.selectedJurisdictions}
                        selectedFacilities={this.state.selectedFacilities}/>
            </div>
        </div>
      </div>
      )
  }
}

export default withAuth(FilterCriteria);