import React, { Component }  from 'react';
import UserContext from '../../context/UserContext';
import InfoButton from '../Common/InfoButton';
import moment from 'moment';
import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from '../../common/FetchUtil';

import './AmendBooking.css';

class AmendBooking extends Component{
    constructor(props){
      super(props);
      this.state = { amendAll: false, amendButtonEnabled: true };
    }

    _onAmendAllChanged = () => {
        this.setState({amendAll: !this.state.amendAll});
    }

    _amendBooking = () => {
        this.props.hideMessages();
        if (this.props.doesValidationPass())
        {
            this.setState({amendButtonEnabled: false});
            fetchWithAuth(this.props.auth, process.env.REACT_APP_BASE_API_URL+ "/api/bookings/" + this.props.bookingId + "?includeAllOccurances="+this.state.amendAll,{
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                        startDate: moment(this.props.startDate).format("YYYY-MM-DDTHH:mm:ss"),
                        endDate: moment(this.props.endDate).format("YYYY-MM-DDTHH:mm:ss"),
                        notes: this.props.notes,
                        jurisdictionId: this.props.jurisdictionId !== 0 ? this.props.jurisdictionId : null,
                        ignoreConflicts: this.props.ignoreConflict,
                        selectedDays: this.props.selectedDays()
                    })
                })
            .then(response => response.json())
            .then(json => {
                if (json.errorCode === 12)
                {
                    this.props.showConflictMessage();
                }
                else if (json.errorCode === -1)
                {
                    this.props.showSuccessMessage(json.message);
                }
                else{
                    this.props.showOtherMessage(json.message);
                }
                this.setState({amendButtonEnabled: true});
            }).catch((error) => {
                this.props.showOtherMessage("There was an error amending the booking.");
            });
        }
        else 
        {
            this.setState({amendButtonEnabled: true});
        }
    }

    componentDidMount()
    {
        
    }

    render(){
        let buttonText = "Amend Booking";

        if (this.state.amendAll === true){
            buttonText = "Amend All Bookings";
        }
        let amendAllCheckbox = "";
        if (this.props.isRecurringBooking === true && this.props.hideAllOccurances === false){
            amendAllCheckbox = (<label className="au-control-input au-control-input-markin-top">
                                    <input className="au-control-input__input" type="checkbox" checked={this.state.amendAll} onChange={this._onAmendAllChanged}></input>
                                    <span className="au-control-input__text">Amend all occurrences</span>
                                    <InfoButton infoMessage="Choosing this option will amend booking for the entire series"/>
                                </label>);
        }
        return (
            <div>
                <button className="au-btn au-btn--primary" onClick={this._amendBooking} disabled={this.state.amendButtonEnabled === false}>{buttonText}</button>
                {amendAllCheckbox}
            </div>)
    }
}


AmendBooking.contextType = UserContext;

export default withAuth(AmendBooking);